/*
* ===================================================
*     NoUI Slider theming
* ===================================================
*/

.noUi-target {
    background: #ddd;
    border-radius: 0;
    border: none;
    box-shadow: none;
    height: 3px;
    margin-top: 10px;
}

.noUi-horizontal .noUi-handle {
    width: 3px;
    height: 14px;
    right: -2px !important;
    top: -5px;
    background: theme-color('dark');
    border: none;
    border-radius: 0;
    cursor: pointer;
    box-shadow: none;
    outline: none;
}

.noUi-horizontal .noUi-handle::before,
.noUi-horizontal .noUi-handle::after {
    display: none;
}

.noUi-connect {
    background: theme-color('dark');
}

.text-primary {
    .noUi-handle, .noUi-connect {
        background: theme-color('primary');
    }
}
.text-secondary {
    .noUi-handle, .noUi-connect {
        background: theme-color('secondary');
    }
}

.nouislider-values {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    color: $gray-600;
    font-size: $font-size-sm;
}
